/* eslint-disable func-names */
import 'ExtensionMethods/array';
import isPresent from 'isPresent';
import Papa from 'papaparse';
import { getProgramInclusion, getRelevantCohorts } from 'Users';
import { regionAwareImageUrlForFormat } from 'regionAwareImage';
import { streamIsManuallyUnlocked } from 'ProgramInclusion';
import { getStreamAndEnsureStored } from 'StoredContent';
import angularModule from '../../lessons_module';
import { streamDashboardPath } from '../../../../contentPaths';
import { streamToIguanaAttrs } from '../../../../stream';

angularModule.factory('Lesson.Stream', [
    '$injector',
    'Lesson.Stream.Chapter', // even though we don't use this, it's a convenient place to make sure it's injected
    $injector => {
        const Iguana = $injector.get('Iguana');
        const IsContentItem = $injector.get('IsContentItem');
        const StreamViewModel = $injector.get('Lesson.Stream.StreamViewModel');
        const StreamProgress = $injector.get('Lesson.StreamProgress');
        const $q = $injector.get('$q');
        const $window = $injector.get('$window');
        const stemmer = $injector.get('stemmer');
        const $rootScope = $injector.get('$rootScope');
        const SiteMetadata = $injector.get('SiteMetadata');
        const Locale = $injector.get('Locale');
        const DialogModal = $injector.get('DialogModal');
        const ErrorLogService = $injector.get('ErrorLogService');
        const ConfigFactory = $injector.get('ConfigFactory');
        const disagreementLogMap = {};
        const frontRoyalStore = $injector.get('frontRoyalStore');

        return Iguana.subclass(function () {
            this.setCollection('lesson_streams');
            this.include(IsContentItem);
            this.alias('Lesson.Stream');
            this.embedsMany('lessons', 'Lesson');
            this.embedsOne('lesson_streams_progress', 'Lesson.StreamProgress');
            this.embedsOne('entity_metadata', 'EntityMetadata');
            this.embedsMany('chapters', 'Lesson.Stream.Chapter');

            // Since the filters for index calls can get really long when building a transcript,
            // we use a post to avoid having get urls that go beyond browser
            // limits.
            this.overrideAction('index', {
                method: 'POST',
                path: 'index',
            });

            this.setCallback('before', 'save', function () {
                // HACK: backfill the lesson_ids value to match lesson_hashes
                // in the chapter hashes for backwards compatibility
                this.chapters.forEach(chapter => {
                    chapter.backfillLessonIds();
                });
            });

            this.defineSetter('exam', function (val) {
                if (!val) {
                    this.time_limit_hours = null;
                }
                this.writeKey('exam', val);
            });

            this.defineSetter('time_limit_hours', function (val) {
                // only exam courses can have time_limit_hours
                if (this.exam) {
                    this.writeKey('time_limit_hours', val);
                }
            });

            Object.defineProperty(this, '_streamCacheForCurrentUser', {
                get() {
                    const userId = $rootScope.currentUser?.id || 'blank';
                    this._streamCache[userId] = this._streamCache[userId] || {};
                    return this._streamCache[userId];
                },
            });

            Object.defineProperty(this, '_streamCacheByLocalePackIdForCurrentUser', {
                get() {
                    const userId = $rootScope.currentUser?.id || 'blank';
                    this._streamCacheByLocalePackId[userId] = this._streamCacheByLocalePackId[userId] || {};
                    return this._streamCacheByLocalePackId[userId];
                },
            });

            this.extend({
                EXAM_EXTENSION_MULTIPLIER: 0.5,
                groupable: true,
                fieldsForEditorList: [
                    'id',
                    'title',
                    'tag',
                    'modified_at',
                    'published_at',
                    'updated_at',
                    'author',
                    'locale',
                    'locale_pack',
                    'chapters',
                ],
                _streamCache: {},
                _streamCacheByLocalePackId: {},

                indexForCurrentUser(options) {
                    return this.index(this._optionsForGetCalls(options));
                },

                getCachedOrShow(streamId, options, meta) {
                    options = this._optionsForGetCalls(options);
                    const streamCache = this._streamCacheForCurrentUser;

                    if (!streamCache[streamId]) {
                        const deferred = $q.defer();

                        // Hack: call show with meta if it's supplied
                        // works around a bug in iguana-mock where it can't deal with
                        // undefined arguments passed to an iguana function in tests
                        const showArguments = [streamId, options];
                        if (meta) {
                            showArguments.push(meta);
                        }
                        this.show(...showArguments).then(
                            response => {
                                deferred.resolve(response.result);
                            },
                            response => {
                                // clear out the cache so that subsequent requests won't always return 404
                                delete streamCache[streamId];
                                return deferred.reject(response);
                            },
                        );
                        streamCache[streamId] = {
                            streamId,
                            promise: deferred.promise,
                        };
                    }
                    return streamCache[streamId].promise.then(stream =>
                        $rootScope.currentUser ? $rootScope.currentUser.progress.replaceProgress(stream) : stream,
                    );
                },

                getCachedForLocalePackId(localePackId, throwIfMissing) {
                    if (!localePackId) {
                        throw new Error('No localePackId passed in');
                    }

                    if (angular.isUndefined(throwIfMissing)) {
                        throwIfMissing = true;
                    }

                    let streamCacheByLocalePackId = this._streamCacheByLocalePackIdForCurrentUser;
                    const streamCache = this._streamCacheForCurrentUser;
                    let stream = streamCacheByLocalePackId[localePackId];

                    // If this stream has been removed from the streamCache,
                    // do not return it.
                    if (stream && !streamCache[stream.id]) {
                        stream = undefined;
                    }

                    // If we didn't find the stream, copy everything over
                    // from the streamCache and see if we find it now
                    if (!stream) {
                        streamCacheByLocalePackId = this._rebuildStreamCacheByLocalePackId();
                    }
                    stream = streamCacheByLocalePackId[localePackId];

                    if (!stream) {
                        if (throwIfMissing) {
                            if (!this.alreadyShowedNoCachedStreamError) {
                                DialogModal.showFatalError();
                                this.alreadyShowedNoCachedStreamError = true;
                            }
                            throw new Error('No cached stream found for locale pack.');
                        }
                    }

                    return stream;
                },

                getCached(streamId) {
                    const stream = this._streamCacheForCurrentUser[streamId].stream;

                    if (!stream) {
                        throw new Error('Stream is not cached');
                    }

                    return stream;
                },

                /*
                    If there is a cached stream and stream.allLessonContentLoaded === true,
                        return a promise with that stream as it's value.
                    Otherwise, call getCachedOrShow, which should load up all the
                        content for the stream.
                */
                showWithFullContentForLesson(streamId, lessonId) {
                    return getStreamAndEnsureStored({
                        id: streamId,
                        loadFullContentForLessonId: lessonId,
                        frontRoyalStore,
                    }).then(streamAttrs => {
                        if (!streamAttrs) return null;
                        const stream = this.new(streamToIguanaAttrs(streamAttrs));
                        return $rootScope.currentUser
                            ? $rootScope.currentUser.progress.replaceProgress(stream)
                            : $q.when(stream);
                    });
                },

                setCache(stream) {
                    // It is intentional, though I admit I don't 100% understand why
                    // at this point, that caching in the _streamCache is kept separate
                    // from caching in the _streamCacheByLocalePackId.  Has something
                    // to do with synchronous vs. asynchronous access.
                    this._streamCacheForCurrentUser[stream.id] = {
                        streamId: stream.id,
                        stream,
                        promise: $q.when(stream),
                    };
                },

                setCachesForCurrentUser(stream) {
                    // In the admin, we sometimes load up streams for a user
                    // other than the current user (like when we download a transcript).
                    // We don't want the progress for that other user to end up in the stream
                    // cache
                    if (
                        !stream.lesson_streams_progress ||
                        stream.lesson_streams_progress.user_id === $rootScope.currentUser?.id ||
                        // We have a lot of specs testing various dashboard functionality that
                        // grab users and streams from fixtures. The ids won't align in those
                        // cases. This should not be a problem in the wild.
                        window.RUNNING_IN_TEST_MODE
                    ) {
                        if (stream.id) {
                            this.setCache(stream);
                        }

                        if (stream.localePackId) {
                            this._streamCacheByLocalePackIdForCurrentUser[stream.localePackId] = stream;
                        }
                    }
                },

                resetCache() {
                    this._streamCache = {};
                    this._streamCacheByLocalePackId = {};
                },

                uncache(streamId) {
                    this._streamCacheForCurrentUser[streamId] = undefined;
                },

                keepLearningStream(playlist, bookmarkedStreams) {
                    const ContentAccessHelper = $injector.get('ContentAccessHelper');

                    let keepLearningStream;

                    // First check playlist courses
                    if (playlist && !playlist.complete) {
                        for (let i = 0; i < playlist.streams.length; i++) {
                            if (!playlist.streams[i].complete && ContentAccessHelper.canLaunch(playlist.streams[i])) {
                                keepLearningStream = playlist.streams[i];
                                break;
                            }
                        }
                    }
                    // Then check bookmarked courses
                    else if (bookmarkedStreams && bookmarkedStreams.length > 0) {
                        for (let j = 0; j < bookmarkedStreams.length; j++) {
                            if (!bookmarkedStreams[j].complete && ContentAccessHelper.canLaunch(bookmarkedStreams[j])) {
                                keepLearningStream = bookmarkedStreams[j];
                                break;
                            }
                        }
                    }

                    return keepLearningStream;
                },

                _optionsForGetCalls(options = {}) {
                    options.filters = options.filters || {};

                    // WARNING: if you change this, you may need to
                    // also change access rules on the server
                    if ($rootScope.currentUser) {
                        const cohorts = getRelevantCohorts($rootScope.currentUser);
                        if (cohorts.length > 0) options.filters.cohort_ids = cohorts.map(c => c.id);
                    } else {
                        options.include_progress = false;
                        options.filters = {
                            ...options.filters,
                            in_locale_or_en: Locale.activeCode,
                            user_can_see: null,
                            in_users_locale_or_en: null,
                        };
                    }

                    options.include_progress ??= true;

                    return options;
                },

                keepLearningLesson(stream) {
                    let keepLearningLesson;
                    const ContentAccessHelper = $injector.get('ContentAccessHelper');
                    stream.orderedLessons.forEach(lesson => {
                        if (!ContentAccessHelper.canLaunch(lesson)) {
                            return;
                        }

                        // complete / coming soon lessons cannot be the keepLearningLesson (but
                        // unstarted available ones can be in this case)
                        if (lesson.complete || lesson.comingSoon) {
                            return;
                        }

                        // if there is not yet a keepLearningLesson, then this
                        // one gets priority over nothing
                        if (!keepLearningLesson) {
                            keepLearningLesson = lesson;
                            return;
                        }

                        // if this lesson has progress and the keepLearningLesson
                        // does not, then this one gets priority
                        if (lesson.lastProgressAt && !keepLearningLesson.lastProgressAt) {
                            keepLearningLesson = lesson;
                            return;
                        }

                        // if this lesson has no progress, then the existing
                        // keepLearningLesson gets priority.  It either has progress,
                        // or it's earlier in the stream's list, or both.
                        if (!lesson.lastProgressAt) {
                            return;
                        }

                        // if this lesson was accessed more recently than the
                        // keepLearningLesson, then it gets priority
                        if (lesson.lastProgressAt > keepLearningLesson.lastProgressAt) {
                            keepLearningLesson = lesson;
                        }
                    });

                    return keepLearningLesson;
                },

                editorUrl(id) {
                    return `/editor/course/${id}/edit`;
                },

                _rebuildStreamCacheByLocalePackId() {
                    const cache = this._streamCacheByLocalePackIdForCurrentUser;
                    Object.keys(cache).forEach(key => {
                        delete cache[key];
                    });

                    // _streamCacheByLocalePackId can only be built after streams
                    // are loaded, since we don't know the locale_pack_id before
                    // that.  This is ok because it is only ever accessed synchronously
                    _.forEach(this._streamCacheForCurrentUser, entry => {
                        if (entry.stream && entry.stream.localePackId) {
                            cache[entry.stream.localePackId] = entry.stream;
                        }
                    });

                    return cache;
                },
            });

            const Stream = this;
            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                // Default values for arrays if not provided
                this.recommended_stream_ids = this.recommended_stream_ids || [];
                this.related_stream_ids = this.related_stream_ids || [];
                this.what_you_will_learn = this.what_you_will_learn || [];
                this.resource_downloads = this.resource_downloads || [];
                this.resource_links = this.resource_links || [];
                this.summaries = this.summaries || [];
                this.chapters = this.chapters || [];

                if (!this.doNotCache) {
                    Stream.setCachesForCurrentUser(this);
                }
                delete this.doNotCache;
            });

            Object.defineProperty(this.prototype, 'factoryName', {
                value: 'Lesson.Stream',
                configurable: true,
            });

            // TranslatableLessonExportSet requires a filename for the zip file that it generates.
            // We use the parameterizedTitle for this, but since the titles for non-English versions
            // can contain characters outside of the English alphabet (which don't play nice with the
            // parameterizedTitle logic), we want to make sure that we use the englishTitle.
            Object.defineProperty(this.prototype, 'zipFilenameForTranslatableLessonExportSet', {
                get() {
                    if (this.locale === 'en') {
                        return SiteMetadata.parameterizedTitle(this.title);
                    }
                    // Use the stream id as a fallback in the event that we can't get the englishTitle.
                    const title = (this.englishTitle && SiteMetadata.parameterizedTitle(this.englishTitle)) || this.id;
                    return `${title}_${this.locale}`;
                },
            });

            Object.defineProperty(this.prototype, 'allLessonContentLoaded', {
                get() {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const lesson of this.lessons) {
                        if (!lesson.allContentLoaded) {
                            return false;
                        }
                    }

                    return true;
                },
            });

            Object.defineProperty(this.prototype, 'utmCampaign', {
                value: 'share_course',
            });

            Object.defineProperty(this.prototype, 'notStarted', {
                get() {
                    return !this.lesson_streams_progress;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'inProgress', {
                get() {
                    return !!this.lesson_streams_progress && this.lesson_streams_progress.inProgress;
                },
            });

            Object.defineProperty(this.prototype, 'complete', {
                get() {
                    return !!this.lesson_streams_progress && this.lesson_streams_progress.complete;
                },
            });

            Object.defineProperty(this.prototype, 'gradable', {
                get() {
                    const assessmentLesson = _.find(this.lessons, lesson => lesson.assessment === true);

                    return this.exam || !!assessmentLesson;
                },
                configurable: true,
            });

            // This functions returns one of the following:
            //  exam score if an exam stream
            //  average of the best assessment scores if all assessments complete and stream is complete
            //  undefined if either the user has not completed it or the stream is not gradable
            Object.defineProperty(this.prototype, 'grade', {
                get() {
                    if (!this.gradable || !this.complete) {
                        return undefined;
                    }

                    if (this.exam && this.lesson_streams_progress) {
                        return this.lesson_streams_progress.official_test_score;
                    }
                    const assessmentLessons = _.filter(this.lessons, {
                        assessment: true,
                    });
                    const scores = _.chain(assessmentLessons)
                        .map(lesson => lesson.bestScore)
                        .compact()
                        .value();

                    if (scores.length === assessmentLessons.length) {
                        return scores.reduce((a, b) => a + b, 0) / scores.length;
                    }
                    return undefined;
                },
            });

            Object.defineProperty(this.prototype, 'progressWaiver', {
                get() {
                    return this.lesson_streams_progress && this.lesson_streams_progress.waiver;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'completeExcludingComingSoonLessons', {
                get() {
                    let complete = true;
                    this.lessons.forEach(lesson => {
                        if (lesson.complete || lesson.comingSoon) {
                            return;
                        }
                        complete = false;
                    });
                    return complete;
                },
            });

            Object.defineProperty(this.prototype, 'lastProgressAt', {
                get() {
                    return (
                        (this.lesson_streams_progress &&
                            this.lesson_streams_progress.last_progress_at &&
                            new Date(1000 * this.lesson_streams_progress.last_progress_at)) ||
                        undefined
                    );
                },
            });

            Object.defineProperty(this.prototype, 'savePromise', {
                get() {
                    if (this.lesson_streams_progress && this.lesson_streams_progress.$$savePromise) {
                        return this.lesson_streams_progress.$$savePromise;
                    }

                    // eslint-disable-next-line no-restricted-syntax
                    for (const lesson of this.lessons) {
                        if (lesson.lesson_progress && lesson.lesson_progress.$$savePromise) {
                            return lesson.lesson_progress.$$savePromise;
                        }
                    }

                    return undefined;
                },
            });

            Object.defineProperty(this.prototype, 'hasLessonProgress', {
                get() {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const lesson of this.lessons) {
                        if (lesson.lastProgressAt) {
                            return true;
                        }
                    }

                    return false;
                },
            });

            Object.defineProperty(this.prototype, 'started', {
                get() {
                    return !!this.lesson_streams_progress;
                },
            });

            // See also stream_progress.rb#time_limit_hours
            Object.defineProperty(this.prototype, 'timeLimitHours', {
                get() {
                    let timeLimitHours = this.time_limit_hours;
                    if ($rootScope.currentUser?.extend_exam_time)
                        timeLimitHours += this.time_limit_hours * Stream.EXAM_EXTENSION_MULTIPLIER;
                    return timeLimitHours;
                },
            });

            Object.defineProperty(this.prototype, 'hasTimeLimit', {
                get() {
                    return !!this.time_limit_hours;
                },
            });

            Object.defineProperty(this.prototype, 'msLeftInTimeLimit', {
                get() {
                    if (this.lesson_streams_progress?.timerIsStarted) {
                        return this.lesson_streams_progress.msLeftInTimeLimit;
                    }

                    if (this.hasTimeLimit) {
                        return this.timeLimitHours * 60 * 60 * 1000;
                    }

                    return null;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'timerIsStarted', {
                get() {
                    if (this.lesson_streams_progress) {
                        return this.lesson_streams_progress.timerIsStarted;
                    }
                    return false;
                },
            });

            Object.defineProperty(this.prototype, 'approxStreamMinutes', {
                get() {
                    let approxTime = 0;
                    if (!this.lessons) {
                        return approxTime;
                    }
                    this.lessons.forEach(lesson => {
                        // use a hard-coded estimate for coming soon lessons, since they're likely to be incomplete
                        if (lesson.comingSoon) {
                            approxTime += 7;
                        } else {
                            approxTime += lesson.approxLessonMinutes;
                        }
                    });
                    return approxTime;
                },
            });

            Object.defineProperty(this.prototype, 'approxAverageLessonMinutes', {
                get() {
                    if (!this.lessons) {
                        return 0;
                    }

                    return this.approxStreamMinutes / this.lessons.length;
                },
            });

            Object.defineProperty(this.prototype, 'streamDashboardPath', {
                get() {
                    if (this.entity_metadata?.canonical_url) {
                        return this.entity_metadata.canonical_url;
                    }
                    return streamDashboardPath({ streamTitle: this.title, streamId: this.id });
                },
            });

            Object.defineProperty(this.prototype, 'orderedLessons', {
                get() {
                    let lessons = [];
                    this.chapters.forEach(chapter => {
                        lessons = lessons.concat(chapter.lessons);
                    });
                    return lessons;
                },
            });

            Object.defineProperty(this.prototype, 'instructionalLessons', {
                get() {
                    return this.orderedLessons.filter(lesson => lesson.instructional);
                },
            });

            Object.defineProperty(this.prototype, 'comingSoonLessons', {
                get() {
                    let lessons = [];
                    this.chapters.forEach(chapter => {
                        lessons = lessons.concat(chapter.comingSoonLessons);
                    });
                    return lessons;
                },
            });

            Object.defineProperty(this.prototype, 'isBlueOcean', {
                get() {
                    return this.title === 'Blue Ocean Strategy';
                },
            });

            Object.defineProperty(this.prototype, 'chaptersCompleted', {
                get() {
                    return this.chapters.filter(chapter => chapter.complete);
                },
            });

            Object.defineProperty(this.prototype, 'chaptersCompletedCount', {
                get() {
                    return this.chaptersCompleted ? this.chaptersCompleted.length : 0;
                },
            });

            Object.defineProperty(this.prototype, 'lessonsCompleted', {
                get() {
                    return this.lessons.filter(lesson => lesson.lesson_progress && lesson.lesson_progress.complete);
                },
            });

            Object.defineProperty(this.prototype, 'lessonsCompletedCount', {
                get() {
                    return this.lessonsCompleted ? this.lessonsCompleted.length : 0;
                },
            });

            Object.defineProperty(this.prototype, 'lessonIds', {
                get() {
                    return _.map(this.lessons, 'id');
                },
            });

            Object.defineProperty(this.prototype, 'contentTopicNames', {
                get() {
                    const code = Locale.activeCode;
                    return _.map(this.locale_pack && this.locale_pack.content_topics, contentTopic =>
                        contentTopic.locales[code] ? contentTopic.locales[code] : contentTopic.locales.en,
                    );
                },
            });

            Object.defineProperty(this.prototype, 'lessonLocalePackIds', {
                get() {
                    return _.map(this.lessons, 'localePackId');
                },
            });

            Object.defineProperty(this.prototype, 'chapterCount', {
                get() {
                    return this.chapters ? Object.keys(this.chapters).length : 0;
                },
            });

            Object.defineProperty(this.prototype, 'lessonCount', {
                get() {
                    return _.chain(this.chapters)
                        .map('lessonIds')
                        .map('length')
                        .reduce((memo, num) => memo + num)
                        .value();
                },
            });

            Object.defineProperty(this.prototype, 'elective', {
                get() {
                    const relevantCohort = $rootScope.currentUser?.relevantCohort;
                    return (
                        !this.exam && relevantCohort && !relevantCohort.requiredStreamPackIdsCache[this.localePackId]
                    );
                },
                configurable: true,
            });

            return {
                groupable: true,

                imageSrc() {
                    return regionAwareImageUrlForFormat(
                        this.image,
                        'original',
                        $injector.get('ConfigFactory').getSync(true),
                    );
                },

                certificateImageSrc() {
                    return this.certificate_image?.formats?.original?.url;
                },

                lessonForId(lessonId, allowUndefined) {
                    this._initializeLessonsByIdMap();

                    if (!this.$$lessonsById[lessonId]) {
                        // eslint-disable-next-line no-restricted-syntax
                        for (const lesson of this.lessons) {
                            if (lesson.id === lessonId) {
                                this.$$lessonsById[lessonId] = lesson;
                                break;
                            }
                        }
                    }

                    if (!this.$$lessonsById[lessonId] && !allowUndefined) {
                        throw new Error(`No lesson found for lesson_id=${lessonId}.`);
                    }

                    return this.$$lessonsById[lessonId];
                },

                /*
                 * Returns an array of key term objects in the format:
                 * {
                 *    text: 'key term',
                 *    chapterTitle: 'chapter title'
                 *    chapterIndex: 0,
                 *    lessonTitle: 'lesson title',
                 *    lessonIndex: 0
                 * }
                 */
                getKeyTerms() {
                    const allTerms = [];

                    this.chapters.forEach(chapter => {
                        chapter.lessons.forEach(lesson => {
                            // don't include any coming soon lessons
                            if (lesson.comingSoon) {
                                return;
                            }

                            lesson.getKeyTermsForDisplay().forEach(({ keyTerm, definition }) => {
                                const k = {
                                    text: keyTerm,
                                    definition: definition?.definition,
                                    definitionVerified: definition?.verified || false,
                                    lesson,
                                    chapterTitle: chapter.title,
                                    chapterIndex: chapter.index,
                                    lessonTitle: lesson.title,
                                    lessonIndex: lesson.chapterLessonsIndex(),
                                    index: lesson.chapterLessonsIndex() + chapter.index * 100,
                                };
                                allTerms.push(k);
                            });
                        });
                    });

                    return allTerms;
                },

                removeChapter(chapter) {
                    Array.remove(this.chapters, chapter);
                    this.removeLessonsIfUnused(chapter.lessons);
                },

                /*
                    In some sense, this method is unnecessary, because the interface does
                    not allow for the same lesson to be used in multiple chapters.
                    But, it seemed safer to remain robust to supporting
                    that in the future, and implement it while this stuff is fresh in my mind,
                    rather than trying to figure out what all the issues with it are in the
                    future.
                */
                removeLessonsIfUnused(lessons) {
                    const usedIds = {};
                    this.chapters.forEach(chapter => {
                        chapter.lessonIds.forEach(lessonId => {
                            usedIds[lessonId] = true;
                        });
                    });

                    lessons.forEach(lesson => {
                        if (lesson && !usedIds[lesson.id]) {
                            Array.remove(this.lessons, lesson);
                            lesson.$$embeddedIn = undefined; // FIXME: Iguana should handle this
                        }
                    });
                },

                addLesson(lesson) {
                    if (!this.lessons.includes(lesson)) {
                        this.lessons.push(lesson);
                    }
                    lesson.$$embeddedIn = this; // FIXME: Iguana should handle this
                },

                createStreamViewModel(options) {
                    return new StreamViewModel(this, options);
                },

                allLessonsComplete() {
                    // It seems wrong to mark a stream as "all lessons complete" if it doesn't contain any lessons.
                    // This seems to happen primarily (maybe even entirely?) with streams marked as "coming soon".
                    if (!this.lessons.length) {
                        return false;
                    }

                    let allComplete = true;

                    this.lessons.forEach(lesson => {
                        if (!lesson.complete) {
                            allComplete = false;
                        }
                    });

                    return allComplete;
                },

                preloadCertificateImage() {
                    if (this.lesson_streams_progress && this.lesson_streams_progress.certificateImageSrc()) {
                        const src = this.lesson_streams_progress.certificateImageSrc();
                        const img = new $window.Image();
                        img.src = src;
                    }
                },

                // Determine if a stream is not_started, in_progress, or completed
                progressStatus() {
                    if (this.lesson_streams_progress) {
                        if (this.lesson_streams_progress.inProgress) {
                            return 'in_progress';
                        }
                        if (this.lesson_streams_progress.complete) {
                            return 'completed';
                        }
                    }
                    return 'not_started';
                },

                currentChapter() {
                    let currentChapter;

                    // Loop backwards so we're always breaking on the furthest bit of progress
                    for (let index = this.chapters.length - 1; index >= 0; index--) {
                        currentChapter = this.chapters[index];
                        const status = currentChapter.progressStatus();

                        // If current chapter is completed, return previous (later) chapter, if it exists
                        if (status === 'completed') {
                            if (this.chapters.length > index + 1) {
                                currentChapter = this.chapters[index + 1];
                            }
                            break;
                        }
                        // If this chapter is in progress, it's the current one
                        else if (status === 'in_progress') {
                            break;
                        }
                        // Else, we've only seen not_started chapters thus far. Keep searching backwards.
                    }

                    // TODO: what if we broke on the last chapter of the stream - is it the current chapter?
                    // Or should there be no current chapter after you complete it?
                    return currentChapter;
                },

                toJasminePP() {
                    return `Stream: "${this.title}" (id=${this.id}) `;
                },

                ensureStreamProgress() {
                    if (!this.lesson_streams_progress) {
                        return StreamProgress.startStream(this);
                    }
                    return this.lesson_streams_progress;
                },

                setCompleteIfAllLessonsComplete() {
                    const streamProgress = this.ensureStreamProgress();
                    if (!streamProgress.complete && this.allLessonsComplete()) {
                        streamProgress.complete = true;

                        // this is just temporary.  The server will override it with server-time
                        // on save.  But if we want to show it in the UI right away, we can do so
                        streamProgress.completed_at = Math.round(new Date().getTime() / 1000);

                        if (this.exam) {
                            const scores = _.chain(this.lessons)
                                .map('lesson_progress')
                                .map('officialTestScore')
                                .filter(score => isPresent(score))
                                .value();

                            if (scores.length < this.lessons.length) {
                                ErrorLogService.notifyInProd(
                                    'Setting official_test_score when not all lessons are complete.',
                                    undefined,
                                    {
                                        streamLocalePackId: this.localePackId,
                                    },
                                );
                            }

                            // We set this here so that it is immediately available in the client,
                            // but the server does not respect this value.  It recalculates on its own.
                            streamProgress.official_test_score =
                                _.reduce(scores, (sum, el) => sum + el, 0) / scores.length;
                        }

                        return true;
                    }

                    return false;
                },

                logInfo() {
                    return {
                        lesson_stream_id: this.id,
                        lesson_stream_complete: this.complete,
                        lesson_stream_version_id: this.version_id,
                        exam: this.exam,
                    };
                },

                getSearchTermsSet() {
                    let texts = [this.title, this.description];
                    this.chapters.forEach(chapter => {
                        texts.push(chapter.title);
                    });

                    texts = texts.concat(this.contentTopicNames);

                    const obj = stemmer.stemmedWordsSet(texts);

                    this.lessons.forEach(lesson => {
                        angular.extend(obj, lesson.getSearchTermsSet());
                    });

                    return obj;
                },

                _initializeLessonsByIdMap() {
                    if (!this.$$lessonsById) {
                        this.$$lessonsById = {};
                        this.lessons.forEach(lesson => {
                            this.$$lessonsById[lesson.id] = lesson;
                        });
                    }
                },

                importMetadata(content) {
                    const self = this;

                    const parsed = Papa.parse(content);
                    const data = parsed.data;
                    const translationIndex = 2;

                    // skip row 0 (headers)

                    self.tag = data[0][translationIndex];
                    self.title = data[2][translationIndex];
                    self.description = data[3][translationIndex];

                    // Note: Alexie said that for now we do not need to worry about
                    // changes to the chapter (and highlight) list during the window
                    // between export and import.
                    _.forEach(self.chapters, (_chapter, i) => {
                        const offset = 3;
                        self.chapters[i].title = data[offset + i][translationIndex];
                    });

                    _.forEach(self.what_you_will_learn, (_highlight, i) => {
                        const offset = 3 + self.chapters.length;
                        self.what_you_will_learn[i] = data[offset + i][translationIndex];
                    });
                },

                getExportableMetadata() {
                    const self = this;

                    const data = [];
                    data.push(['', 'Original', 'Translation']);
                    data.push(['Course Title', self.tag, '']);
                    data.push(['Course Title', self.title, '']);
                    data.push(['Description', self.description, '']);
                    _.forEach(self.chapters, (_chapter, i) => {
                        data.push([`Chapter Title ${i + 1}`, self.chapters[i].title, '']);
                    });
                    _.forEach(self.what_you_will_learn, (_highlight, i) => {
                        data.push([`Course Highlight ${i + 1}`, self.what_you_will_learn[i]]);
                    });

                    const blob = new Blob([Papa.unparse(data)], {
                        type: 'data:text/csv;charset=utf-8',
                    });

                    return {
                        filename: `${self.title}-metadata.csv`,
                        content: Papa.unparse(data),
                        blob,
                    };
                },

                examOpenTime(user) {
                    const stream = this;
                    if (!this.exam || !user || !user.relevantCohort) {
                        return null;
                    }

                    if (streamIsManuallyUnlocked(getProgramInclusion(user), stream.localePackId)) {
                        return null;
                    }

                    const period = user.relevantCohort.periods.find(p => p.requiresStream(stream.localePackId));
                    return period ? period.startDate : null;
                },

                beforeLaunchWindow(user) {
                    const examOpenTime = this.examOpenTime(user);
                    return !!(examOpenTime && examOpenTime > this._now());
                },

                notifyOnDisagreementBetweenLessonAndStreamProgress() {
                    if (disagreementLogMap[this.localePackId]) return;

                    // When we dump data from prod, we don't dump lesson progress, so this would
                    // always error in staging and development
                    const config = ConfigFactory.getSync(true);
                    if (config?.appEnvType() !== 'production') return;

                    let message;
                    const allLessonsComplete = this.allLessonsComplete();

                    if (allLessonsComplete && !this.complete) {
                        message = 'Stream rendered in the UI is not complete even though all the lessons are';
                    }

                    if (this.complete && !allLessonsComplete) {
                        message = 'Stream rendered in the UI is complete even though not all the lessons are';
                    }

                    if (!message) return;

                    ErrorLogService.notifyInProd(message, undefined, {
                        streamLocalePackId: this.localePackId,
                        streamProgress: this.lesson_streams_progress && {
                            id: this.lesson_streams_progress.id,
                            updated_at: this.lesson_streams_progress.updated_at,
                        },
                        lessonProgress: this.lessons.map(l => ({
                            id: l.lesson_progress?.id,
                            updated_at: l.lesson_progress?.updated_at,
                        })),
                    });
                    disagreementLogMap[this.localePackId] = true;
                },

                getStreamLessonTutorBotRecord(lessonId) {
                    // Elvis operator here just in case there are streams out in people's FrontRoyalStore
                    // that don't have stream_lesson_tutor_bot_records when we roll this code.
                    return this.stream_lesson_tutor_bot_records?.find(record => record.lesson_id === lessonId);
                },

                previousLessonOutline(nextLessonId) {
                    const previousLesson = this.previousInstructionalLesson(nextLessonId);
                    if (!previousLesson) return null;
                    const streamLessonTutorBotRecord = this.getStreamLessonTutorBotRecord(previousLesson.id);
                    if (!streamLessonTutorBotRecord) return null;
                    return streamLessonTutorBotRecord.outline;
                },

                previousInstructionalLesson(nextLessonId) {
                    const providedLessonIndex = this.instructionalLessons.map(l => l.id).indexOf(nextLessonId);
                    if (providedLessonIndex === -1) {
                        throw new Error(`Lesson with id ${nextLessonId} not found in stream`);
                    }
                    if (providedLessonIndex === 0) {
                        return null;
                    }
                    return this.orderedLessons[providedLessonIndex - 1];
                },

                // mockable
                _now() {
                    return new Date();
                },
            };
        });
    },
]);
